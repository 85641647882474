import { formValidation } from "../validations/formValidation";
import { setCookie } from '../common/setCookies';
import { ajaxRequest } from "../handlers/ajaxRequest";
export function login(event) {
    if (formValidation(event)) {

        const url = "backend/login";

        ajaxRequest(event, url, './')
        .then(responseData => {
            // Handle success response data here
            setCookie('token', responseData.token, 12);
        })
        .catch(error => {
            // Handle error here
            console.error(error);
        });

    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("signInBtn")) {
        login(event);
    }
});