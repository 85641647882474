import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
export function updateSubcategoryForm(event) {
    if (formValidation(event)) {
        const url = "backend/update-subcategory";
        ajaxRequest(event, url, 'goback');
    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("updateSubcategoryBtn")) {
        updateSubcategoryForm(event);
    }
});