import SimpleMDE from 'simplemde/dist/simplemde.min.js';
import 'simplemde/dist/simplemde.min.css';
import TurndownService from 'turndown';

function htmlspecialchars_decode(encodedString) {
    var textarea = document.createElement("textarea");
    textarea.innerHTML = encodedString;
    return textarea.value;
}

    var simplemdeArr = {};
    document.querySelectorAll('[markdownEditor="true"]').forEach(function (ele) {
        var hideIconsArr = ["heading-1", "heading", "fullscreen", "side-by-side", "table"];
        // var autoSaveObj={
        //   enabled: true,
        //   uniqueId: ele.className,
        //   delay: 1000,
        // }
        const escapedHtmlContent = ele.getAttribute("data-value");

        const getValue = htmlspecialchars_decode(escapedHtmlContent);

        simplemdeArr[ele.id] = new SimpleMDE({
            // autosave: autoSaveObj,
            forceSync: false,
            promptURLs: true,
            headerIds:false,
            showIcons: ["heading-2", "heading-3", "code", "table"],
            hideIcons: hideIconsArr,
            element: ele
        });

        // Set the value of the textarea using the PHP data
        if (getValue) {
            var turndownService = new TurndownService();
            var markdown = turndownService.turndown(getValue);
            simplemdeArr[ele.id].value(markdown);
        }
    });
    export {simplemdeArr}