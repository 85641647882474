import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
export function addSubcategoryForm(event) {
    if (formValidation(event)) {
        const url = "backend/add-subcategory";
        ajaxRequest(event, url, './');
    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("addSubcategoryBtn")) {
        addSubcategoryForm(event);
    }
});