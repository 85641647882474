    "use strict";
    import 'flowbite';
    import "./common/textareaAutoResizer";

    import "./extras/addProductForm";

    import "./extras/updateBusinessInfoForm";

    import "./extras/updatecategoryForm";

    import "./extras/updateSubcategoryForm";

    import "./extras/updateBrandForm";

    import "./extras/updateProductForm";

    import "./extras/addCategoryForm";
    import "./extras/addSubcategoryForm";
    import "./extras/addGalleryForm";

    import "./extras/addBrandForm";

    import "./extras/addHeroForm";

    import "./extras/deleteCategory";
    import "./extras/deleteSubcategory";
    import "./extras/deleteBrand";
    import "./extras/deleteProduct";
    import "./extras/deleteGallery";
    import "./extras/deleteHero";
    import "./extras/login";

    import "./extras/fileBrowse"



    import { readFileShowPreview } from "./common/readFileShowPreview";

    var isImageUpdate=0;
    document.addEventListener("change", function(event) {
      if (event.target.id === "productBrochureInpFile") {
        // productBrochureReadURL(event.target);
        readFileShowPreview(event.target, 1, 'product_brochure', '.selectedProductBrochureContainer', 'append');
      }
  
      if (event.target.id === "productBrochureUpdateFile") {
        // productBrochureReadURL(event.target);
        readFileShowPreview(event.target, 1, 'product_brochure', '.selectedProductBrochureContainer', 'replace');
      }
      if (event.target.id === "productImageInpFile") {
        readFileShowPreview(event.target, 6, 'product_img', '.selectedProductImgContainer', 'append');
      }
  
      if (event.target.id === "productImageUpdateFile") {
        if(isImageUpdate == 0){
          document.querySelector(".selectedProductImgContainer").innerHTML ='';
          isImageUpdate=1;
        }
        readFileShowPreview(event.target, 6, 'product_img', '.selectedProductImgContainer', 'append');
      }
  
      if(event.target.id === "categoryImgInpFile"){
        readFileShowPreview(event.target, 1, 'category_img', '.selectedCategoryImgContainer', 'append');
      }
  
      if(event.target.id === "categoryImgUpdateFile"){
        readFileShowPreview(event.target, 1, 'category_img', '.selectedCategoryImgContainer', 'replace');
      }
  
      if(event.target.id === "brandImgInpFile"){
        readFileShowPreview(event.target, 1, 'brand_img', '.selectedBrandImgContainer', 'append');
      }
  
      if(event.target.id === "brandImgUpdateFile"){
        readFileShowPreview(event.target, 1, 'brand_img', '.selectedBrandImgContainer', 'replace');
      }
  
      if(event.target.id === "galleryImgInpFile"){
        readFileShowPreview(event.target, 6, 'gallery_img', '.selectedGalleryImgContainer', 'append');
      }
  
      if(event.target.id === "heroImgInpFile"){
        readFileShowPreview(event.target, 1, 'hero_img', '.selectedHeroImgContainer', 'append');
      }
      if(event.target.id === "heroVideoInpFile"){
        readFileShowPreview(event.target, 1, 'hero_video', '.selectedHeroVideoContainer', 'append');
      }
  
      if(event.target.id === "brandLogoImgInpFile"){
        readFileShowPreview(event.target, 1, 'brand_logo', '.selectedBrandLogoImgContainer', 'replace');
      }
    });