
const categoriesArr = [];
const subcategoriesArr = [];

const parentCheckboxs = document.querySelectorAll('.parentCheckbox');

// Add event listener to each parent Checkbox button
parentCheckboxs.forEach(function (parentCheckbox) {
    parentCheckbox.addEventListener('change', function () {
        // Uncheck all child Checkbox buttons associated with the parent
        const parentID = this.getAttribute('data-parent-id');
        const childCheckboxs = document.querySelectorAll('.childCheckbox[data-parent-id="' + parentID + '"]');
        const dataType = this.getAttribute('data-type');
        const dataId = this.getAttribute('data-category-id');
        const dataName= this.value;
        childCheckboxs.forEach(function (childCheckbox) {
            childCheckbox.checked = false;
            removeCategoryIdFromArray(childCheckbox.getAttribute('data-category-id'));
            removeCategoryFromInputBox(childCheckbox.value);
        });
        if (this.checked) {
            if (dataType == "category") {
                addCategoryIdToArray(dataId);
                // Function is about to adding selected category to input box
                addCategoryToInputBox(dataName);
            }
        } else {
            if (dataType == "category") {
                removeCategoryIdFromArray(dataId);
                // Function is about to removing selected category from input box
                removeCategoryFromInputBox(dataName);
            }
        }

    });
});

// Get all child Checkbox buttons
const childCheckboxs = document.querySelectorAll('.childCheckbox');

// Add event listener to each child Checkbox button
childCheckboxs.forEach(function (childCheckbox) {
    childCheckbox.addEventListener('change', function () {
        // Find the associated parent Checkbox button
        const parentID = this.getAttribute('data-parent-id');
        const parentCheckbox = document.querySelector('.parentCheckbox[data-parent-id="' + parentID + '"]');
        const parentDataId = document.querySelector('.parentCheckbox[data-parent-id="' + parentID + '"]').getAttribute("data-category-id");
        const dataType = this.getAttribute('data-type');
        const dataId = this.getAttribute('data-subcategory-id');
        const dataName= this.value;
        const parentDataVal= document.querySelector('.parentCheckbox[data-parent-id="' + parentID + '"]').value;
        // Check the parent Checkbox button if at least one child is checked
        if (this.checked) {
            parentCheckbox.checked = true;
            if (dataType == "subcategory") {
                addCategoryIdToArray(parentDataId);
                addSubCategoryIdToArray(dataId);
                // Function is about to adding selected category to input box
                addCategoryToInputBox(dataName);
                addCategoryToInputBox(parentDataVal);
            }
        } else {
            if (dataType == "subcategory") {

                removeSubCategoryIdFromArray(dataId);
                removeCategoryIdFromArray(parentDataId);
                // Function is about to removing selected category from input box
                removeCategoryFromInputBox(dataName);
            }
            // Uncheck the parent Checkbox button if all children are unchecked
            const childCheckboxs = document.querySelectorAll('.childCheckbox[data-parent-id="' + parentID + '"]');
            const allUnchecked = Array.from(childCheckboxs).every(function (childCheckbox) {
                return !childCheckbox.checked;
            });
            if (allUnchecked) {
                parentCheckbox.checked = false;
                removeCategoryIdFromArray(parentDataId);
                removeCategoryFromInputBox(parentDataVal);
            }
        }
    });
});

function addCategoryToInputBox(dataName){
    const isValExists=document.querySelector("#product-categories").value.split("+").includes(dataName);
    if(!isValExists){
        if(dataName === ''){
            document.querySelector("#product-categories").value=dataName;
        }else{
            document.querySelector("#product-categories").value+=dataName+'+'
        }
    }
}

function removeCategoryFromInputBox(dataName){
    var valuesArray = document.querySelector("#product-categories").value.split('+');
    var newValueArray = valuesArray.filter(function(value) {
        return value !== dataName;
    });
    document.querySelector("#product-categories").value = newValueArray.join('+');
}

function removeCategoryIdFromArray(dataId){
    const index = categoriesArr.indexOf(dataId);
    if (index !== -1) {
        categoriesArr.splice(index, 1);
    }
}

function removeSubCategoryIdFromArray(dataId){
    const index = subcategoriesArr.indexOf(dataId);
    if (index !== -1) {
        subcategoriesArr.splice(index, 1);
    }
}

function addCategoryIdToArray(parentDataId){
    categoriesArr.push(parentDataId);
}

function addSubCategoryIdToArray(dataId){
    subcategoriesArr.push(dataId);
}
let addCategoriesToProduct= document.querySelector(".addCategoriesToProduct");
if(addCategoriesToProduct){
    addCategoriesToProduct.addEventListener("click", function(e){
        e.target.closest("form").querySelectorAll('[data-type="category"]').forEach(element => {
            if(element.checked){
                const category_id = element.getAttribute("data-category-id");
                if (!categoriesArr.includes(category_id)) {
                    categoriesArr.push(category_id);
                }
            }
        });
    
        e.target.closest("form").querySelectorAll('[data-type="subcategory"]').forEach(element => {
            if(element.checked){
                const subcategory_id = element.getAttribute("data-subcategory-id");
                if (!subcategoriesArr.includes(subcategory_id)) {
                    subcategoriesArr.push(subcategory_id);
                }
            }
        });
    });
}

export { categoriesArr, subcategoriesArr }