import $ from 'jquery';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import { userTimezone } from '../common/userTimezone';
import { csrf } from '../common/csrf';
import { baseURL } from '../common/baseUrl';
import { formData } from '../common/formData';

let isRequestInProgress = false; // Flag to track ongoing requests

const ajaxRequest = function(event, url, redirectTo) {
  if (isRequestInProgress) {
    return Promise.reject(new Error('Request in progress')); // Reject with an error if a request is ongoing
  }

  isRequestInProgress = true;

  const parentForm = event.target.closest("form");

  if (parentForm) {
      parentForm.querySelectorAll('input, textarea:not([markdownEditor="true"]), select').forEach(element => {
          if (element.getAttribute("name")) {
              const name = element.getAttribute("name");
              formData.set(name, element.value);
          }
      });
  } else {
      console.error("Parent form not found.");
  }

  formData.set('timezone', userTimezone);

  const ajaxOptions = {
    url: baseURL + url,
    type: 'POST',
    dataType: 'json',
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
    headers: {
      'X-CSRF-TOKEN': csrf
    },
    success: function (res) {
      const req = res['request'];
      if (req === 'success') {
        new Notify({
          status: 'success',
          title: 'Success',
          text: res['msg'],
          autoclose: true,
          effect: 'slide',
        });
      } else {
        new Notify({
          status: 'error',
          title: 'Error',
          text: res['msg'],
          autoclose: true,
          effect: 'slide',
        });
      }
    },
    error: function () {
      new Notify({
        status: 'error',
        title: 'Sorry',
        text: 'Something went wrong',
        autoclose: true,
        effect: 'slide',
      });
    },
    complete: function(result) {
      setTimeout(() => {
        isRequestInProgress = false; // Reset the flag when the request is complete
        if(result["responseText"]){
          if(JSON.parse(result["responseText"])['request']=="success"){
            if(redirectTo=='goback'){
              history.back();
            }else{
              location.href = redirectTo;
            }
          }
        }
      }, 1000);
    }
  };

  return $.ajax(ajaxOptions);
};

export { ajaxRequest };
