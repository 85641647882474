import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css';
let isButtonDisabled = false;
export function formValidation(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    
    const forms = document.querySelectorAll("form");
    let isValid = true;

    forms.forEach(form => {
        const inputElements = form.querySelectorAll(".input");
        if (!validateInputSequentially(inputElements, 0)) {
            isValid = false;
        }
    });

    return isValid;
}

function validateInputSequentially(inputElements, currentIndex) {
    if (currentIndex >= inputElements.length) {
        return true;
    }

    const element = inputElements[currentIndex];
    const validation = element.getAttribute("data-validation");

    if (validation === "required") {
        const validationMsg = element.getAttribute("data-validation-msg");
        const validationLength = element.getAttribute("data-validation-length");

        if (element.getAttribute("data-custom-length")) {
            if (element.getAttribute("data-custom-length") < validationLength) {
                element.focus();
                notification(validationMsg, () => {
                    validateInputSequentially(inputElements, currentIndex + 1);
                });
                return false;
            } else {
                return validateInputSequentially(inputElements, currentIndex + 1);
            }
        } else {
            if (element.value.trim().length < validationLength) {
                element.focus();
                notification(validationMsg, () => {
                    validateInputSequentially(inputElements, currentIndex + 1);
                });
                return false;
            } else if (element.getAttribute("type") === "email") {
                if (!validateEmail(element.value)) {
                    element.focus();
                    notification("Invalid email format", () => {
                        validateInputSequentially(inputElements, currentIndex + 1);
                    });
                    return false;
                } else {
                    return validateInputSequentially(inputElements, currentIndex + 1);
                }
            } else {
                return validateInputSequentially(inputElements, currentIndex + 1);
            }
        }
    } else {
        return validateInputSequentially(inputElements, currentIndex + 1);
    }
}

function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function notification(validationMsg, callback) {
    new Notify({
        status: 'error',
        title: 'Required',
        text: validationMsg,
        autoclose: true,
        effect: 'slide',
        onOpen: callback // Callback to proceed to the next input validation
    });
}
