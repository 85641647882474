import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
export function deleteProduct(event) {
    if (formValidation(event)) {
        if (confirm('Are you sure you want to delete this record?')) {
            const url = "backend/delete-product";
            ajaxRequest(event, url, './');
        }
    }
}

const delBtn = document.querySelector(".deleteProductBtn");
if(delBtn){
    delBtn.addEventListener("click", function(event){
        deleteProduct(event);
    });
}