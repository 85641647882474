import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
export function updateBrandForm(event) {
    if (formValidation(event)) {
        const url = "backend/update-brand";
        ajaxRequest(event, url, 'goback');
    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("updateBrandBtn")) {
        updateBrandForm(event);
    }
});