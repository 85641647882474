import { formData } from "./formData";
import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css';
var newDocArray;
function readFileShowPreview(input, docSize, docArray, appendTo, fetchType) {
    newDocArray = docArray;
    const file = input.files[0];

    // Check if the file with the same name already exists in the FormData array
    const existingFiles = formData.getAll(`${newDocArray}[]`);
    const fileAlreadyExists = existingFiles.some(existingFile => existingFile.name === file.name);

    if (fileAlreadyExists) {
        new Notify({
            status: 'error',
            title: 'Alert',
            text: 'File already added!',
            autoclose: true,
            effect: 'slide',
        });
        return; // Don't proceed further
    }

    if (existingFiles.length >= docSize) {
        new Notify({
            status: 'error',
            title: 'Error',
            text: `You can only select up to ` + docSize + ` files`,
            autoclose: true,
            effect: 'slide',
        });
        return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
        const isImage = file.type.startsWith('image/');
        const isVideo = file.type.startsWith('video/');

        var content = '';

        if (isImage) {
            // Initialize the JavaScript Image object.
            var image = new Image();
            var checkFileStatus = true; // Default to true

            // Set the Base64 string returned from FileReader as the source.
            image.src = e.target.result;

            // Validate the file height and width.
            image.onload = function () {
                var height = this.height;
                var width = this.width;
                if (height > 10000 || width > 10000) {
                    new Notify({
                        status: 'error',
                        title: 'Error',
                        text: 'Height and width should stay within the maximum limits of 10000 pixels in height and 10000 pixels in width',
                        autoclose: true,
                        effect: 'slide',
                    });
                    input.value = ''; // Clear the input field
                    checkFileStatus = false; // Set to false when the image exceeds limits
                }
                content = `<img src="${e.target.result}" class="object-cover rounded-lg h-full w-full">`;
                // Continue with processing
                if (checkFileStatus) {
                    formData.append(`${newDocArray}[]`, file);
                    const html = `
                        <div class="selectedDocItem relative bg-[#d8d0c34f] dark:bg-gray-800/50 hover:bg-[#d8d0c370] dark:hover:bg-gray-800">
                            ${content}
                            <button type="button" data-name="${file.name}" class="cursor-pointer absolute right-0 top-0 bg-white rounded-full p-1.5 flex items-center justify-center z-50 shadow border removeSelectedDocItem">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" width="20" height="20">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>`;
                    const element = document.querySelector(appendTo);
                    if (fetchType == 'replace') {
                        element.innerHTML = html;
                    } else {
                        element.insertAdjacentHTML('beforeend', html);
                    }
                }
            };
        } else if (isVideo) {
            content = `<video src="${e.target.result}" class="object-cover rounded-lg h-full w-full" controls></video>`;
            formData.append(`${newDocArray}[]`, file);

            // Continue with processing for non-image files
            const html = `
                <div class="selectedDocItem relative bg-[#d8d0c34f] dark:bg-gray-800/50 hover:bg-[#d8d0c370] dark:hover:bg-gray-800">
                    ${content}
                    <button type="button" data-name="${file.name}" class="cursor-pointer absolute right-0 top-0 bg-white rounded-full p-1.5 flex items-center justify-center z-50 shadow border removeSelectedDocItem">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" width="20" height="20">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>`;
            const element = document.querySelector(appendTo);
            if (fetchType == 'replace') {
                element.innerHTML = html;
            } else {
                element.insertAdjacentHTML('beforeend', html);
            }
        } else {
            content = `<div class="p-3 lg:p-6 w-max flex flex-col gap-4">${file.name}</div>`;
            formData.append(`${newDocArray}[]`, file);

                    // Continue with processing for non-image files
            const html = `
                <div class="selectedDocItem relative bg-[#d8d0c34f] dark:bg-gray-800/50 hover:bg-[#d8d0c370] dark:hover:bg-gray-800">
                    ${content}
                    <button type="button" data-name="${file.name}" class="cursor-pointer absolute right-0 top-0 bg-white rounded-full p-1.5 flex items-center justify-center z-50 shadow border removeSelectedDocItem">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" width="20" height="20">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>`;
            const element = document.querySelector(appendTo);
            if (fetchType == 'replace') {
                element.innerHTML = html;
            } else {
                element.insertAdjacentHTML('beforeend', html);
            }
        }
    };

    reader.readAsDataURL(file);


    function removeSelectedDocItem() {
        // const fileName = $(this).data("name");
        const fileName = this.dataset.name;
        var array = formData.getAll(`${newDocArray}[]`);
        var filteredArray = array.filter(file => file.name !== fileName);
    
        formData.delete(`${newDocArray}[]`); // Remove the previous key from FormData
        for (const file of filteredArray) {
            formData.append(`${newDocArray}[]`, file);
        }
        // $(this).parent(".selectedDocItem").remove();
        this.closest(".selectedDocItem").remove();
    
        document.querySelector("#"+input.id).value = ''; 
    }

    document.addEventListener("click", function(event) {
        // Traverse up the DOM hierarchy to find the parent button with the class "removeSelectedDocItem"
        const button = event.target.closest(".removeSelectedDocItem");
    
        if (button) {
            removeSelectedDocItem.call(button); // Call the function with the button element as context
        }
    });
    
}

export {readFileShowPreview}