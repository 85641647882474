import { formValidation } from "../validations/formValidation";
import { formData } from "../common/formData";
import { simplemdeArr } from "../common/mde";
import { ajaxRequest } from "../handlers/ajaxRequest";
import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css';
export function updateBusinessInfoForm(event) {
    if (formValidation(event)) {
        const url = "backend/update-business-info";
        const render = simplemdeArr["aboutCompany"].value();
        const aboutCompany = simplemdeArr["aboutCompany"].options.previewRender(render);
        formData.set('about_company', aboutCompany);
        const totalImages = formData.getAll('brand_logo[]').length;
        if (totalImages==0) {
            new Notify ({
              status: 'error',
              title: 'Required',
              text: 'Select brand logo',
              autoclose: true,
            });
            return;
        }
        ajaxRequest(event, url, './');
    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("updateBusinessInfoBtn")) {
        updateBusinessInfoForm(event);
    }
});