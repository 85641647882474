import { formValidation } from "../validations/formValidation";
import { categoriesArr, subcategoriesArr } from "../features/categorySelection";
import { selectedBrand } from '../features/brandSelection';
import { formData } from "../common/formData";
import { simplemdeArr } from "../common/mde";
import { ajaxRequest } from "../handlers/ajaxRequest";
import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css';
export function addProductForm(event) {
    if (formValidation(event)) {
        const url = "backend/add-product";
        formData.set('categories', [...new Set(categoriesArr)]);
        formData.set('subcategories', [...new Set(subcategoriesArr)]);
        formData.set('brand_id', selectedBrand);
        const render = simplemdeArr["fullDesc"].value();
        const fullDescription = simplemdeArr["fullDesc"].options.previewRender(render);
        formData.set('full_description', fullDescription);
        const totalImages = formData.getAll('product_img[]').length;
        if (totalImages==0) {
            new Notify ({
              status: 'error',
              title: 'Required',
              text: 'Select atleast 1 Image',
              autoclose: true,
            });
            return;
        }else if (totalImages > 6) {
            new Notify ({
              status: 'error',
              title: 'Required',
              text: 'You can only select up to 6 image files',
              autoclose: true,
            });
            return;
        }
        ajaxRequest(event, url, './');
    }
}

document.addEventListener("click", function (event) {
  if (event.target.classList.contains("addProductBtn")) {
    addProductForm(event);
  }
});