import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
import { formData } from "../common/formData";
import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css';
export function addGalleryForm(event) {
    if (formValidation(event)) {
        const totalImages = formData.getAll('gallery_img[]').length;
        if (totalImages==0) {
            new Notify ({
              status: 'error',
              title: 'Required',
              text: 'Select atleast 1 Image',
              autoclose: true,
            });
            return;
        }else if (totalImages > 6) {
            new Notify ({
              status: 'error',
              title: 'Required',
              text: 'You can only select up to 6 image files',
              autoclose: true,
            });
            return;
        }
        const url = "backend/add-gallery";
        ajaxRequest(event, url, './');
    }
}

document.addEventListener("click", function (event) {
  if (event.target.classList.contains("addGalleryBtn")) {
    addGalleryForm(event);
  }
});