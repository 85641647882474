import { formValidation } from "../validations/formValidation";
import { ajaxRequest } from "../handlers/ajaxRequest";
import { categoriesArr, subcategoriesArr } from "../features/categorySelection";
import { formData } from "../common/formData";
import { simplemdeArr } from "../common/mde";
import { selectedBrand } from '../features/brandSelection';
export function updateProductForm (event) {
    if (formValidation(event)) {
        const url = "backend/update-product";
        formData.set('categories', [...new Set(categoriesArr)]);
        formData.set('subcategories', [...new Set(subcategoriesArr)]);
        formData.set('brand_id', selectedBrand);
        const render = simplemdeArr["fullDesc"].value();
        const fullDescription = simplemdeArr["fullDesc"].options.previewRender(render);
        formData.set('full_description', fullDescription);
        ajaxRequest(event, url, 'goback');
    }
}

document.addEventListener("click", function (event) {
    if (event.target.classList.contains("updateProductBtn")) {
        updateProductForm(event);
    }
});