export function textareaAutoResizer() {
  // Get references to all textarea elements
  const textareas = document.querySelectorAll('textarea');

  // Function to adjust the textarea height based on content
  function adjustTextareaHeight(textarea) {
    textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
    if(textarea.scrollHeight!==0){
      textarea.style.height = textarea.scrollHeight + 'px'; // Set the new calculated height
    }
  }

  // Add event listener for input events (typing, pasting, etc.) for each textarea
  textareas.forEach((textarea) => {
    textarea.addEventListener('input', function () {
      adjustTextareaHeight(this);
    });
  });

  // Adjust the textarea height when the page loads (in case there's pre-filled content) for each textarea
  window.addEventListener('load', function () {
    textareas.forEach((textarea) => {
      adjustTextareaHeight(textarea);
    });
  });
}
textareaAutoResizer();
